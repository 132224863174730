import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "src/context/user";
import { ERROR_CODES, isErrorCode } from "../error";
import { UrlParser } from "../util";
import { useTokenStore } from "./useToken";
import { useCookieConsent } from "../components/cookie-consent/cookieConsentProvider";

interface UseQueryParamSessionOptions<T extends string> {
  keys: T[];
  lsKey: string;
  requiredKeys?: T[];
  acquire: (data: { [K in T]: string }) => Promise<string>;
  associate: (unassociatedSid: string) => Promise<void>;
  dontRetryOnError?: ERROR_CODES[];
}

export const useQueryParamSession = <T extends string>({
  keys,
  lsKey,
  requiredKeys,
  acquire,
  associate,
  dontRetryOnError = [],
}: UseQueryParamSessionOptions<T>) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const { userState } = useUser();
  const { token } = useTokenStore();
  const { savedPreferences } = useCookieConsent();

  const [unassocSessionSid, setUnassocSessionSid] = useState<string | null>(null);
  const parsedUrl = useMemo(() => new UrlParser(searchParams, keys), [keys, searchParams]);

  const unassociatedLsKey = `u${lsKey}`;

  const attemptToAssociateSessionToken = useCallback(
    async (unassociatedSid: string) => {
      try {
        localStorage.removeItem(unassociatedLsKey);
        await associate(unassociatedSid);
      } catch (e) {
        // only retry if the session hasn't expired, or the user isn't trying to affiliate themselves
        if (!isErrorCode(e, [ERROR_CODES.SESSION_EXPIRED, ...dontRetryOnError])) {
          localStorage.setItem(unassociatedLsKey, unassociatedSid);
          return;
        }
      }
    },
    [unassociatedLsKey, associate, dontRetryOnError],
  );

  useEffect(() => {
    if (!userState.retrieved && token) {
      return;
    }

    const queryParamData = parsedUrl.extractParamData(requiredKeys);
    if (!queryParamData) {
      return;
    }

    const handleData = async () => {
      const newUrl = parsedUrl.URLWithParamsStripped();
      router.replace(newUrl, undefined, { shallow: true });
      try {
        const sid = await acquire(queryParamData);
        setUnassocSessionSid(sid);
      } catch (e) {
        console.warn(e);
      }
    };

    handleData();
  }, [parsedUrl, router, userState.retrieved, token, acquire, requiredKeys]);

  useEffect(() => {
    const sidFromLs = localStorage.getItem(unassociatedLsKey);
    const itemSid = sidFromLs ?? unassocSessionSid;

    const shouldSaveCampaign = userState.initialized && !userState.user && !!itemSid;
    const canSaveCampign = savedPreferences.marketing;

    // user not logged in - store campaign data in ls
    if (shouldSaveCampaign && canSaveCampign) {
      setUnassocSessionSid(null);
      localStorage.setItem(unassociatedLsKey, itemSid);
      return;
    }

    if (!userState.user || !itemSid) {
      return;
    }

    attemptToAssociateSessionToken(itemSid);
  }, [
    userState,
    attemptToAssociateSessionToken,
    unassocSessionSid,
    unassociatedLsKey,
    savedPreferences,
  ]);
};
