import { ClassValue, clsx } from "clsx";
import Link from "next/link";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

const ALL_FEATURES = {
  "1": { desc: "Feature 1 (this is a cool one)" },
  "2": { desc: "Feature 2" },
  "3": { desc: "Feature 3" },
  "4": { desc: "Feature 4" },
  "5": { desc: "Feature 5" },
  "6": { desc: "Feature 6" },
  "7": { desc: "Feature 7" },
};
type FeatureKey = keyof typeof ALL_FEATURES;
type PlanConfig = {
  name: string;
  price: number;
  features: FeatureKey[];
  spotlight?: boolean;
  spotlightMessage?: string;
  cta: string;
  ctaHref?: string;
};

const FEATURE_KEYS = Object.keys(ALL_FEATURES) as FeatureKey[];
const PLANS: PlanConfig[] = [
  {
    name: "Free",
    price: 0,
    features: ["1", "2", "3"],
    cta: "Get Free",
    ctaHref: "#",
  },
  {
    name: "Basic",
    price: 10,
    features: ["1", "2", "3", "4", "5"],
    spotlight: true,
    spotlightMessage: "Most Popular",
    cta: "Get Basic",
    ctaHref: "#",
  },
  {
    name: "Pro",
    price: 30,
    features: ["1", "2", "3", "4", "5", "6", "7"],
    cta: "Get Pro",
    ctaHref: "#",
  },
];

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function Button({
  children,
  intent = "primary",
  href,
  onClick,
}: PropsWithChildren<{
  intent?: "primary" | "secondary";
  href?: string;
  onClick?: () => void;
}>) {
  const className = cn(
    "font-semibold bg-origin-border rounded-md py-2 px-4 w-full transition-all no-underline text-center block border duration-300 hover:no-underline",
    intent === "primary" &&
      "bg-linear-to-r from-brand-blue to-brand-purple text-white hover:scale-110 border-transparent",
    intent === "secondary" && "bg-brand-gray800 text-white border-brand-blue hover:bg-brand-blue",
  );
  const commonProps = { className, onClick };
  return href ? (
    <Link href={href} {...commonProps}>
      {children}
    </Link>
  ) : (
    <button type="button" {...commonProps}>
      {children}
    </button>
  );
}

function FeatureCell({
  children,
  roundedBottom,
  bt,
  br,
  bb,
  bl,
}: PropsWithChildren<{
  roundedBottom?: boolean;
  bt?: boolean;
  br?: boolean;
  bb?: boolean;
  bl?: boolean;
}>) {
  return (
    <div
      className={cn(
        "flex-row-reverse items-center justify-end gap-3 md:flex-row md:justify-center",
        "relative flex h-[40px] w-full px-4",
        roundedBottom && "rounded-b-lg",
      )}
    >
      {bt && <div className="absolute top-0 right-0 left-0 z-10 h-[1px] bg-zinc-800" />}
      {br && <div className="absolute top-0 right-0 bottom-0 z-10 w-[1px] bg-zinc-800" />}
      {bb && <div className="absolute right-0 bottom-0 left-0 z-10 h-[1px] bg-zinc-800" />}
      {bl && <div className="absolute top-0 bottom-0 left-0 z-10 w-[1px] bg-zinc-800" />}
      {children}
    </div>
  );
}

function PlanCard({ plan }: { plan: PlanConfig }) {
  const { spotlight } = plan;
  const includedFeatures = plan.features;
  return (
    <div
      className={cn(
        "relative w-full max-w-[300px] rounded-lg bg-brand-gray800 shadow-md md:max-w-[200px]",
        spotlight && "mt-10 mb-4 sm:mx-[2px] sm:my-0",
      )}
    >
      {spotlight && (
        <div className="-inset-x-[2px] -bottom-[2px] -top-6 absolute rounded-lg bg-linear-to-t from-brand-blue to-brand-purple">
          <h5 className="m-auto mt-1 w-fit text-xs">{plan.spotlightMessage}</h5>
        </div>
      )}
      <div className="relative h-fit rounded-lg bg-brand-gray800">
        <div className="rounded-t-lg p-4">
          <h2 className="">{plan.name}</h2>
          <h3>{plan.price}</h3>
          <Button href={plan.ctaHref} intent={spotlight ? "primary" : "secondary"}>
            {plan.cta}
          </Button>
        </div>
        <div className="">
          {FEATURE_KEYS.map((f, i) => (
            <FeatureCell key={f} bt={true} roundedBottom={i === FEATURE_KEYS.length - 1}>
              <div className="whitespace-nowrap text-xs md:hidden">{ALL_FEATURES[f].desc}</div>
              <div>
                {includedFeatures.includes(f) ? (
                  <span className="icon-[mdi--check] h-[18px] w-[18px] text-green-600" />
                ) : (
                  <span className="icon-[mdi--close] h-[18px] w-[18px] text-zinc-700" />
                )}
              </div>
            </FeatureCell>
          ))}
        </div>
      </div>
    </div>
  );
}

function PricingSection() {
  return (
    <div className="mt-4 mb-4 flex w-full flex-col items-center justify-center sm:flex-row md:mt-8">
      <div className="mt-auto hidden h-fit md:block">
        {FEATURE_KEYS.map((f, i) => (
          <FeatureCell key={f} bt={true}>
            <div className="w-full whitespace-nowrap text-xs">{ALL_FEATURES[f].desc}</div>
          </FeatureCell>
        ))}
      </div>
      {PLANS.map((p) => (
        <PlanCard key={p.name} plan={p} />
      ))}
    </div>
  );
}

export default PricingSection;
