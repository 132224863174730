import React, { useRef } from "react";
import { renderIf } from "../../util";
import Button from "../general/buttonv2";
import Switch from "../general/switch";
import { CookieType, useCookieConsent } from "./cookieConsentProvider";
import { useCollapse } from "react-collapsed";
import { Portal, Dialog } from "@ark-ui/react";

const CollapsibleInfoPanel = ({
  title,
  prefKey,
  children,
  defaultToggle,
}: {
  title: string;
  children: React.ReactNode;
  prefKey?: CookieType;
  defaultToggle?: boolean;
}) => {
  const { updatePrefs, unsavedPreferences } = useCookieConsent();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: false,
  });

  const icon = isExpanded ? "ic--baseline-minus" : "ic--baseline-plus";

  return (
    <div className="my-3 rounded-lg bg-brand-duskslate ">
      <div className="text-base">
        <div className="flex flex-row items-center hover:cursor-pointer" {...getToggleProps()}>
          <span className={`px-8 icon-[${icon}] h-[18px] w-[18px]`} />
          <span className="m-0 p-4 pl-0 font-semibold text-lg">{title}</span>
          {renderIf(prefKey, (key) => (
            <div className="ml-auto pr-4 ">
              <Switch
                defaultValue={defaultToggle}
                id={`toggle-${title}`}
                onToggle={(toggled) => updatePrefs(key, toggled)}
                value={unsavedPreferences[key]}
              />
            </div>
          ))}
        </div>
        <div {...getCollapseProps()}>
          <div className="px-16 pb-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const CookiePreferencesModal = () => {
  const { essentialOnly, showModal, setShowModal, setShowBanner, savePrefs, hasSetConsent } =
    useCookieConsent();

  const save = () => {
    savePrefs();
    setShowModal(false);
    setShowBanner(false);
  };

  const saveEssential = () => {
    essentialOnly();
    setShowModal(false);
    setShowBanner(false);
  };

  return (
    <Dialog.Root
      open={showModal}
      closeOnEscape={hasSetConsent}
      closeOnInteractOutside={hasSetConsent}
      onOpenChange={({ open }) => setShowModal(open)}
    >
      <Portal>
        <Dialog.Backdrop className="pointer-events-none fixed top-0 left-0 z-[101] h-full w-full bg-black opacity-50" />
        <Dialog.Positioner className="fixed top-0 left-0 z-[105] flex h-screen w-full items-center justify-center">
          <Dialog.Content
            className="relative w-4/5 rounded-lg border-brand-baby-blue border-t-[18px] bg-brand-slate p-5 px-[30px] pb-[30px] shadow-2xl md:w-1/2"
            onClick={(e) => e.stopPropagation()}
          >
            {hasSetConsent ? (
              <Dialog.CloseTrigger className="absolute top-[-30px] right-[15px] flex h-[40px] w-[40px] items-center justify-center rounded-lg border-[4px] border-brand-baby-blue bg-brand-slate ">
                <span className="icon-[material-symbols--close] h-[26px] w-[26px] bg-white hover:cursor-pointer" />
              </Dialog.CloseTrigger>
            ) : null}
            <span className="mt-0 font-bold text-lg">Cookie preferences</span>
            <br />
            <span className="mt-0">You can select your cookie preferences here.</span>
            <CollapsibleInfoPanel title="Functional Cookies (Necessary)">
              <span className="m-0">
                These cookies are essential for the website to function and cannot be switched off
                in our systems.
              </span>
              <br />
              Examples: Login authentication, shopping cart functionality.
            </CollapsibleInfoPanel>
            <CollapsibleInfoPanel prefKey="analytics" title="Analytics Cookies">
              <span className="m-0">
                These cookies help us measure and improve the performance of our site by collecting
                anonymous data.
              </span>
              <br />
              Examples: Remembering language preferences.
            </CollapsibleInfoPanel>
            <CollapsibleInfoPanel prefKey="marketing" title="Marketing Cookies">
              <span className="m-0">
                These cookies are used to deliver personalized ads and track your browsing activity
                across websites.
              </span>
              <br />
              Examples: Tracking page load times
            </CollapsibleInfoPanel>
            <CollapsibleInfoPanel prefKey="preferences" title="Preferences Cookies">
              <span className="m-0">
                These cookies remember your settings, like language or layout, to provide a
                personalized and seamless experience.
              </span>
              <br />
              Examples: Remembering theme preferences.
            </CollapsibleInfoPanel>
            <div className="mt-4 flex">
              <Button noSparkle={true} onClick={saveEssential}>
                Essential only
              </Button>
              <Button noSparkle={true} wrapperClass="ml-auto" onClick={save} buttonPrimary={true}>
                Save
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};
