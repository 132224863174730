import React, { useEffect, useState } from "react";
import * as RadixSwitch from "@radix-ui/react-switch";

interface SwitchProps {
  id: string;
  onToggle?: (toggled: boolean) => void;
  defaultValue?: boolean;
  value?: boolean;
  disabled?: boolean;
}

const Switch = ({ id, onToggle, defaultValue = false, value, disabled }: SwitchProps) => {
  const [on, setOn] = useState(value ?? defaultValue ?? false);
  const [textClass, setTextClass] = useState("opacity-100");

  useEffect(() => {
    if (typeof value === "undefined") {
      return;
    }
    setOn(value);
  }, [value]);

  const onCheckedChange = (val: boolean) => {
    setOn(val);
    onToggle?.(val);

    setTextClass("opacity-0");

    setTimeout(() => {
      setTextClass("opacity-100");
    }, 50);
  };

  const bgClass = () => {
    if (disabled) {
      return "bg-white-500";
    }

    return on ? `bg-brand-blue` : `bg-white-300`;
  };

  return (
    <div
      className="relative flex align-center"
      // dont bubble events up
      onClick={(e) => e.stopPropagation()}
    >
      <RadixSwitch.Root
        defaultChecked={defaultValue}
        disabled={disabled}
        onCheckedChange={onCheckedChange}
        checked={value}
        className={`flex h-[25px] w-[63px] content-center overflow-hidden hover:cursor-pointer ${bgClass()} ${disabled ? `cursor-not-allowed` : ""} relative rounded-full border-0 p-[3px]`}
        id={id}
      >
        <RadixSwitch.Thumb className="absolute top-[4px] h-[17px] w-[17px] translate-x-[2px] rounded-full bg-white-100 transition duration-200 hover:cursor-pointer data-[state=checked]:translate-x-[39px]" />
        <span
          className={`absolute ${on ? `left-[7px]` : `right-[9px]`} font-bold text-sm uppercase ${textClass}`}
        >
          {on ? "on" : "off"}
        </span>
      </RadixSwitch.Root>
    </div>
  );
};

export default Switch;
