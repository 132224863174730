import { datadogRum } from "@datadog/browser-rum";
import Button from "components/general/buttonv2";
import asyncComponent from "components/split/asyncComponent";
import { navLinksArray } from "data/variables";
import Link from "next/link";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useUser } from "src/context/user";
import { gtag_set_user_id } from "../../lib/ga";
import { twc } from "react-twc";
const Logo = asyncComponent(() => import("components/general/logo"));
import debounce from "debounce";
import MobileMenuButton from "components/nav/mobileMenuButton";
import MobileMenuPanel from "components/nav/mobileMenuPanel";
import { twMerge } from "tailwind-merge";

const SubMenuButton = twc.button`${twMerge(`
  relative flex cursor-pointer items-center 
  whitespace-nowrap border-none bg-transparent 
  px-4 py-2 font-[var(--font-family-standard)] 
  text-[0.9rem] text-[var(--text-color)] 

  after:absolute after:top-0 after:left-0 after:hidden 
  after:h-full after:w-full after:skew-x-[-5deg] 
  after:transform after:border after:border-[#888] 
  after:content-[''] hover:after:block
`)}`;

const SubMenuLink = twc.a`${twMerge(`
  relative cursor-pointer whitespace-nowrap 
  text-[var(--text-color)] 


  after:absolute after:top-0 after:left-0 after:hidden 
  after:h-full after:w-full after:transform after:border 
  after:border-[#888] after:content-[''] 
  
  hover:no-underline hover:after:block
`)}`;

const NavbarLink = twc.a`
  relative whitespace-nowrap p-2 font-medium text-[0.9rem] 
  text-[var(--text-color)] hover:no-underline hover:after:block 
  inline
  
  after:absolute after:top-0 after:left-0 after:h-full 
  after:w-full after:transform after:border after:border-[#888]
  after:hidden after:skew-x-[-5deg] after:transform 
  
  data-[active=true]:after:block 
`;

const submenuLinks = [
  {
    href: "/services/discord",
    label: "Discord",
    accentColor: "#5865f2",
  },
  {
    href: "/services/twitter",
    label: "Twitter",
    accentColor: "#189eed",
  },
  {
    href: "/services/facebook",
    label: "Facebook",
    accentColor: "#1f73eb",
  },
  {
    href: "/services/reddit",
    label: "Reddit",
    accentColor: "#ff4500",
  },
  {
    href: "/services",
    label: "All (30+ more)",
    accentColor: "#5865f2",
  },
];

const visibleWidthClasses = `hidden 
data-[visible-width=*]:inline data-[visible-width=xs]:xs:inline 
data-[visible-width=2xs]:2xs:inline data-[visible-width=sm]:sm:inline 
data-[visible-width=md]:md:inline data-[visible-width=lg]:lg:inline`;

const Submenu = ({ dark, onMenuClose, visibleWidth }) => {
  return (
    <div
      data-visible-width={visibleWidth}
      className={`group relative flex h-full items-center ${visibleWidthClasses}`}
    >
      <SubMenuButton>
        <span>Services</span>
        <svg
          className="ml-[6px] h-[10px] w-[10px] fill-[var(--text-color)]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </SubMenuButton>
      <div className="absolute top-full hidden flex-col bg-[var(--raised-background-color)] text-[var(--text-color)] opacity-0 group-hover:flex group-hover:opacity-100">
        {submenuLinks.map((link) => (
          <Link key={link.href} href={link.href} passHref legacyBehavior>
            <SubMenuLink onClick={onMenuClose}>
              <div
                className="absolute top-0 left-0 h-full w-[5px]"
                style={{ backgroundColor: link.accentColor }}
              />
              <div className="p-2 pl-4">{link.label}</div>
            </SubMenuLink>
          </Link>
        ))}
      </div>
    </div>
  );
};

const Navbar = ({ dark }) => {
  const posthog = usePostHog();
  const router = useRouter();
  const { userState } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  useEffect(() => {
    if (userState.user) {
      posthog?.identify(userState.user.id, {
        email: userState.user.email,
      });

      datadogRum.setUser({
        id: userState.user.id,
        email: userState.user.email,
        hasPremium: !!userState.user.subscription?.isActive,
      });

      gtag_set_user_id(userState.user.id);
    }
  }, [posthog, userState, userState.user?.id]);

  const navLinks = useMemo(() => {
    const isLoggedIn = userState.initialized && userState.user;
    const concat = isLoggedIn
      ? [{ label: "Account", link: "/account", visibleWidth: "2xs" }]
      : [{ label: "Log in", link: "/login", visibleWidth: "2xs" }];

    return [...navLinksArray, ...concat];
  }, [userState.initialized, userState.user]);

  useEffect(() => {
    if (!isMenuOpen || window.innerWidth > 1024) {
      return;
    }

    const onResizeHandler = debounce(() => {
      if (window.innerWidth > 1024 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    }, 100);

    window.addEventListener("resize", onResizeHandler);

    return () => {
      window.removeEventListener("resize", onResizeHandler);
    };
  }, [isMenuOpen]);

  return (
    <>
      <div className="fixed z-[99] flex h-[70px] w-full items-center justify-center border-white-900 border-b-1 bg-[var(--background-color)]/80 backdrop-blur-xs">
        <nav className="justify-space-between flex w-full max-w-[1280px] items-center">
          <Link href="/" passHref legacyBehavior>
            <a className="relative flex items-center p-2 px-[1rem] py-[0.5rem] text-[var(--text-color)] no-underline">
              <Logo size="h-[2.5rem]" />
            </a>
          </Link>
          <ul className="ml-auto flex list-none items-center gap-[.4rem] lg:gap-0">
            {navLinks.map((value) => {
              if (value.label === "Services") {
                return (
                  <Submenu
                    visibleWidth={value.visibleWidth}
                    key="services"
                    dark={dark}
                    onMenuClose={handleCloseMenu}
                  />
                );
              }
              return (
                <li
                  key={value.label}
                  className={`relative hidden p-2 ${visibleWidthClasses}`}
                  data-visible-width={value.visibleWidth}
                >
                  <Link href={value.link} passHref legacyBehavior>
                    <NavbarLink data-active={router?.pathname === value.link}>
                      {value.label}
                    </NavbarLink>
                  </Link>
                </li>
              );
            })}
            <Button
              className="transition-none md:mx-4"
              to="/download"
              buttonBuddy={true}
              alignSelf="center"
              onClick={handleCloseMenu}
            >
              Download
            </Button>

            <MobileMenuButton menuDisplay={isMenuOpen} toggle={setIsMenuOpen} />
          </ul>
        </nav>
      </div>

      <MobileMenuPanel menuDisplay={isMenuOpen} toggle={setIsMenuOpen} />
    </>
  );
};

export default Navbar;
