import { DateTime } from "luxon";
import Link from "next/link";
import { useEffect, useState } from "react";
import { bannerData } from "src/data/bannerData";
import useWindowSize from "../../hooks/useWindowSize";
import Image from "next/image";
import { usePathname } from "next/navigation";

const Banner = ({}) => {
  const expirationDate = DateTime.fromISO(bannerData.expirationDate);
  const [countdown, setCountdown] = useState(expirationDate.diffNow());
  const pathname = usePathname();

  const [activeBanner, setActiveBanner] = useState(true);

  const handleBannerActivity = () => {
    setActiveBanner(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setActiveBanner(bannerData.isActive && expirationDate > DateTime.now());
    }
    const intervalId = setInterval(() => {
      if (expirationDate <= DateTime.now()) {
        setActiveBanner(false);
        clearInterval(intervalId);
      }
      setCountdown(expirationDate.diffNow());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { width } = useWindowSize();

  if (!bannerData.isActive) {
    return null;
  }

  if (pathname === bannerData.path) {
    return null;
  }

  return (
    <>
      <div
        className={`fixed bottom-1 z-100 w-full rounded-md bg-brand-purple text-white opacity-95 shadow-custom sm:top-0 sm:h-12 sm:rounded-none sm:opacity-90 ${activeBanner ? "flex" : "hidden"} ${bannerData.showsExpirationMobile ? "h-32" : "h-16"}`}
      >
        <a onClick={handleBannerActivity}>
          <Image
            className={`absolute mt-4 cursor-pointer sm:ml-[95%] ${bannerData.showsExpirationMobile ? "ml-[90%]" : "ml-[92%]"}
          `}
            src="/images/svgs/exit_banner.svg"
            alt="mobile preview"
            height={16}
            width={16}
          />
        </a>
        <Link
          href={bannerData.path}
          passHref
          legacyBehavior
          style={{ textDecoration: "none", color: "#DEDEDE" }}
        >
          <a
            className={`flex h-12 w-full flex-col items-start justify-start text-base text-white no-underline hover:no-underline sm:flex-row sm:items-center sm:justify-center sm:text-lg `}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Image
                className={`mt-3 ml-3 sm:mt-0 sm:mr-1 sm:ml-0 ${bannerData.showsExpiration ? "sm:flex" : "sm:hidden"} ${bannerData.showsExpirationMobile ? "flex" : "hidden"};`}
                src="/images/svgs/clock.svg"
                alt="mobile preview"
                height={20}
                width={20}
              />
              <span
                className={`mt-5 ml-3 h-0 text-base text-white sm:mt-0 sm:text-xl ${bannerData.showsExpiration ? "sm:flex" : "sm:hidden"} ${bannerData.showsExpirationMobile ? "flex" : "hidden"}
              `}
                suppressHydrationWarning
              >
                Expires in {countdown?.toFormat("dd':'hh':'mm':'ss'")}
              </span>
              <div className="mt-5 ml-2 flex h-[23px] w-[80px] items-center justify-center rounded-xl bg-yellow-100 font-bold text-black sm:hidden ">
                25% OFF
              </div>
            </div>
            <div
              className={`bottom-12 ml-5 flex w-[90%] items-center justify-center font-semibold text-base text-white sm:w-[80%] sm:text-xl ${bannerData.showsExpirationMobile ? "mt-[-0.5rem]" : "mt-[-0.1rem]"};`}
            >
              <div className="mt-0 mr-5 hidden h-[28px] w-[100px] items-center justify-center rounded-xl bg-yellow-100 font-bold text-black sm:flex ">
                25% OFF
              </div>
              {width > 1140 ? bannerData.bannerText : bannerData.bannerTextMobile}
            </div>
          </a>
        </Link>
        <span className="icon-[openmoji--flag-russia] invisible absolute"></span>
        <span className="icon-[openmoji--flag-turkey] invisible absolute"></span>
      </div>
      {activeBanner && <div style={{ marginTop: "25px" }} />}
    </>
  );
};

export default Banner;
