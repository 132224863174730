import { http } from "./http";
import { ReferralData } from "./lib/rewardful";
import { CampaignData, FullPublicUser } from "./types";

export interface APIResponse<T = null> {
  message: string;
  error: null;
  data: T;
  ok: true;
}

export interface AcquireSessionBody {
  sid: string;
}

export interface StripeCheckoutSessionBody {
  discountCode?: string;
  priceVariant?: string;
  affiliateId?: string;
  redirectOrigin?: string;
  referralData?: ReferralData;
}

export interface StripeCheckoutPortalBody {
  redirectOrigin?: string;
}

export interface AffiliateData {
  affiliateCode: string;
}

export interface MeResponse {
  user: FullPublicUser;
}

export interface LoginSubmitRepsonse {
  user: FullPublicUser;
  session: string;
}

export interface AffiliateDashboardData {
  isConfigured: boolean;
  paypalEmail: string;
  default: string;
  custom: string;
  stats: {
    totalPaidToDate: number;
    totalSales: number;
    allTimeTraffic: number;
    trafficSinceLastPayment: number;
    unpaidEarnings: number;
    lastPaymentMs: number;
  };
}

export interface SendContactEmail {
  targetEmail: string;
  companyName: string;
  companySize: string;
  additionalDetails?: string;
}

export class API {
  static acquireCampaignSessionToken({ source, campaign }: CampaignData) {
    return http.request<APIResponse<AcquireSessionBody>>({
      method: "post",
      url: `/api/measure/campaign`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ a: source, b: campaign }),
    });
  }

  static async associateCampaignSessionToken(sessionId: string) {
    await http.request<APIResponse>({
      method: "post",
      url: `/api/measure/campaign/upgrade`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        us: sessionId,
      },
    });
  }

  static acquireAffiliateSessionToken(affiliateCode: string) {
    return http.request<APIResponse<AcquireSessionBody>>({
      method: "post",
      url: `/api/measure/affiliate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { affiliateCode },
    });
  }

  static async associateAffiliateSessionToken(sessionId: string) {
    await http.request<APIResponse>({
      method: "post",
      url: `/api/measure/affiliate/upgrade`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        us: sessionId,
      },
    });
  }

  static async incrementAffiliateTraffic(code: string) {
    await http.request({
      method: "POST",
      url: "/api/affiliate/increment",
      headers: {
        "content-type": "application/json",
      },
      data: {
        affiliateCode: code,
      },
    });
  }

  static createStripeCheckoutSession(body: StripeCheckoutSessionBody) {
    return http.request({
      method: "POST",
      url: "/api/checkout/create-checkout-session",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    });
  }

  static createStripePortalSession(body: StripeCheckoutSessionBody) {
    return http.request({
      method: "post",
      url: "/api/checkout/create-portal-session",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    });
  }

  static async me() {
    const resp = await http.request<APIResponse<MeResponse>>({
      method: "GET",
      url: "/api/user/me",
    });

    return resp.data;
  }

  static async updatePaypalEmail(newEmail: string) {
    await http.request<APIResponse>({
      method: "post",
      url: "/api/affiliate/update-paypal-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: newEmail,
      },
    });
  }

  static async createDefaultCode() {
    await http.request({
      method: "post",
      url: "/api/affiliate/create-default-code",
    });
  }

  static async createCustomCode(code: string) {
    await http.request({
      method: "post",
      url: "/api/affiliate/create-code",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        code,
      },
    });
  }

  static async affiliateStats() {
    const resp = await http.request<APIResponse<AffiliateDashboardData>>({
      method: "GET",
      url: "/api/affiliate/stats",
    });

    return resp.data.data;
  }

  static async submitLogin(emailToken: string, secToken: string, referralData?: ReferralData) {
    const resp = await http.request<APIResponse<LoginSubmitRepsonse>>({
      method: "post",
      url: "/api/user/login/submit",
      data: {
        token: emailToken,
        securityToken: secToken,
        referralData: referralData,
      },
    });

    return resp.data;
  }

  static async sendContactEmail(body: SendContactEmail) {
    const resp = await http.request<APIResponse<LoginSubmitRepsonse>>({
      method: "post",
      url: "/api/contact/send-contact-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    });

    return resp.data;
  }
}
