import { navLinksArray } from "data/variables";
import Link from "next/link";
import { useUser } from "src/context/user";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: fixed;
  top: 3.67rem;
  right: 0;
  height: calc(100% - 3.65rem); 
  width: 100%;
  border: 1px solid var(--background-color);
  overflow-y: auto;
  background-color: rgba(16, 15, 15, 0.9); 
  backdrop-filter: blur(10px);
  opacity: ${(props) => (props.$active ? 1 : 0)};
  transform: ${(props) => (props.$active ? "translate3d(0rem, 1rem, 0)" : 0)};
  pointer-events: ${(props) => (props.$active ? "auto" : "none")};
 border-radius: 0rem;
  display: none;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 2.0rem;

  transition: 0.09s linear;
  padding: 2.3rem;
  z-index: 2;
  text-align: left; 
  a {
    left: 0.7rem;
    color: var(--text-color);
    text-decoration: none;
  }
  
    
  @media screen and (max-width: 1140px) {
    display: flex;
    align-items: flex-start; 
  }
`;

const MobileMenuPanel = ({ menuDisplay, toggle }) => {
  const { userState } = useUser();
  const isLoggedIn = userState.initialized && userState.user;

  return (
    <StyledWrapper $active={menuDisplay}>
      <span className="ml-[0.2rem] font-medium text-white-400">Product</span>
      {isLoggedIn ? (
        <Link href={"/account"} onClick={() => toggle(false)}>
          Account
        </Link>
      ) : (
        <Link href={"/login"} onClick={() => toggle(false)}>
          Login
        </Link>
      )}
      <Link href={"/"} onClick={() => toggle(false)}>
        Home
      </Link>
      <Link href="/download" onClick={() => toggle(false)}>
        Download
      </Link>
      {navLinksArray.map((value) => {
        return value.label === "Support" ? (
          <a key={value.label} href="https://redact.dev/support/">
            {value.label}
          </a>
        ) : (
          <Link href={value.link} key={value.label} onClick={() => toggle(false)}>
            {value.label}
          </Link>
        );
      })}
      <span className="ml-[0.2rem] font-base text-white-400">Company</span>
      <Link href="/affiliates" onClick={() => toggle(false)}>
        Affiliates
      </Link>
      <Link href="/press-kit" onClick={() => toggle(false)}>
        Press Kit
      </Link>
      <Link href="/terms" onClick={() => toggle(false)}>
        Terms & Conditions
      </Link>
      <Link href="/privacy" onClick={() => toggle(false)}>
        Privacy
      </Link>
    </StyledWrapper>
  );
};

export default MobileMenuPanel;
