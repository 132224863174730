import React from "react";
import Button from "../general/buttonv2";
import { Bold } from "../general/util";
import { useCookieConsent } from "./cookieConsentProvider";
import Link from "next/link";
import { CatImage } from "./cookieDeclaration";

export const CookieConsentBanner: React.FunctionComponent = () => {
  const { savePrefs, setShowBanner, showBanner, setShowModal, hasSetConsent, essentialOnly } =
    useCookieConsent();

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed bottom-[20px] z-101 flex w-full flex-col items-center justify-between sm:flex-row md:bottom-[40px]`">
      <div className="relative w-full lg:w-3/5 xl:w-[50%] 2xl:w-[45%]">
        <CatImage width={150} height={150} wrapperClass="absolute top-[-110px] right-[20px]" />
        <div className="relative m-auto flex w-[90%] flex-col rounded-lg border-brand-baby-blue border-t-[18px] bg-brand-slate p-[20px] pt-[8px] pb-[20px] shadow-lg sm:p-[30px]">
          {hasSetConsent ? (
            <div
              onClick={() => setShowBanner(false)}
              className="absolute top-[-30px] right-[15px] flex h-[40px] w-[40px] items-center justify-center rounded-lg border-[4px] border-brand-baby-blue bg-brand-slate hover:cursor-pointer"
            >
              <span className="icon-[material-symbols--close] h-[26px] w-[26px] bg-white" />
            </div>
          ) : null}
          <span className="m-0 mb-0 p-0 text-sm sm:mt-0 sm:text-base">
            We use <Link href="https://redact.dev/cookies">cookies</Link> to give you the best
            online experience. Strictly necessary cookies are <Bold>on</Bold> by default. Additional
            cookies are off by default. See our privacy policy{" "}
            <Link href="https://redact.dev/privacy">here</Link>
          </span>
          <div className="mt-3 flex w-full flex-col-reverse flex-wrap sm:flex-row sm:flex-nowrap md:mb-0 [&_button]:block">
            <div className="mt-[12px] flex w-full sm:mt-0 sm:mb-0 sm:w-auto ">
              <Button
                $accent="#111111"
                wrapperClass="sm:ml-0 w-full"
                className="w-full justify-center sm:w-auto"
                noSparkle={true}
                onClick={() => setShowModal(true)}
              >
                Manage preferences
              </Button>
            </div>
            <div className="ml-0 flex w-full sm:ml-auto sm:w-auto [&>div]:w-[calc(50%-5px)] sm:[&>div]:w-auto">
              <Button
                noSparkle={true}
                $accent="#111111"
                wrapperClass="w-1/2 sm:w-auto"
                className="w-full justify-center sm:w-auto"
                onClick={essentialOnly}
              >
                Essential only
              </Button>
              <Button
                wrapperClass="ml-[10px] sm:ml-2 w-1/2 sm:w-auto"
                className="w-full justify-center sm:w-auto"
                noSparkle={true}
                buttonPrimary={true}
                onClick={() => savePrefs({ marketing: true, analytics: true, preferences: true })}
              >
                Accept all
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
