import { ReadonlyURLSearchParams } from "next/navigation";
import { Simplify } from "./types";

type ExtractFromRecord<Optional extends any[], Required extends any[] = []> = {
  [K in Optional[number]]?: string;
} & {
  [K in Required[number]]: string;
};

export class UrlParser<const T extends string[]> {
  constructor(
    private params: ReadonlyURLSearchParams,
    private keys: T,
  ) {}

  extractParamData<const U extends T[number][] = []>(
    mustHave?: U,
  ): Simplify<ExtractFromRecord<T, U>> | null {
    if (mustHave) {
      const hasRequiredKeys = mustHave.every((key) => this.params.get(key));
      if (!hasRequiredKeys) {
        return null;
      }
    }

    return this.keys.reduce(
      (prev, key) => ({ ...prev, [key]: this.params.get(key) ?? undefined }),
      {},
    ) as Simplify<ExtractFromRecord<T, U>>;
  }

  URLWithParamsStripped() {
    const params = new URLSearchParams(this.params.toString());
    for (const key of this.keys) {
      params.delete(key);
    }

    const { protocol, host, pathname } = window.location;
    const paramString = params.size > 0 ? `?${params.toString()}` : ``;
    const newUrl = `${protocol}//${host}${pathname}${paramString}`;

    return newUrl;
  }
}

export const errorProxyWrapper = <T extends object>(obj: T, e: Error): T =>
  new Proxy(obj, {
    get: () => {
      throw e;
    },
    apply: () => {
      throw e;
    },
  });

export const capitalize = (t: string) => t.charAt(0).toUpperCase() + t.slice(1);

type Renderable = React.ReactNode | (() => React.ReactNode);

export const renderIf = <T, U extends Renderable>(
  condition: T | null | undefined,
  value: U | ((arg: T) => U),
) => (condition ? (typeof value === "function" ? value(condition) : value) : null);
