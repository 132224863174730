export const CodeSnippet = ({ code }: { code: string }) => {
  return (
    <div className={`flex`}>
      <span
        title={code}
        className="overflow-hidden text-ellipsis whitespace-nowrap rounded bg-gray-200 px-1.5 py-0.5 font-mono text-gray-800 text-sm dark:bg-gray-800 dark:text-gray-200"
      >
        {code}
      </span>
    </div>
  );
};
