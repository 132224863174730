import debounce from "debounce";
import { useEffect, useState } from "react";
const useWindowSize = () => {
  const [[width, height], setWindowSize] = useState([0, 0]);

  useEffect(() => {
    setWindowSize([window.innerWidth, window.innerHeight]);

    const windowSizeHandler = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    const debounceHandler = debounce(windowSizeHandler, 300);

    window.addEventListener("resize", debounceHandler);

    return () => {
      window.removeEventListener("resize", debounceHandler);
    };
  }, []);

  return { width, height };
};

export default useWindowSize;
