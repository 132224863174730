import Link from "next/link";
import { useTheme } from "components/services/themeContext";
import asyncComponent from "components/split/asyncComponent";

const Logo = asyncComponent(() => import("components/general/logo"));

const Footer = () => {
  const copyrightString = `© ${new Date().getFullYear()} Redact - All rights reserved`;
  const { theme } = useTheme();
  const isDark = theme !== "light";

  const textColor = isDark ? "text-[#DEDEDE]" : "text-[#4D4D4D]";
  const headerColor = isDark ? "text-white-100" : "text-white-500";
  const linkClass = `flex text-lg ${textColor} opacity-65 transition-opacity ease-in-out hover:opacity-100`;

  return (
    <div className="flex h-auto justify-center overflow-hidden border-white-900 border-t py-8 text-xs lg:py-0 lg:text-base">
      <div className="flex w-100% max-w-[1280px] flex-col items-center justify-center md:flex-row md:justify-between">
        <div className=" flex w-full flex-col">
          <div className=" mt-10 flex flex-col-reverse sm:flex-row">
            <div className="mt-6 ml-4 flex sm:mt-10 sm:ml-16 md:mt-1 md:ml-5">
              <Logo size="h-16 sm:h-12 w-auto" />
            </div>
            <div className="mt-8 grid grid-cols-2 gap-y-8 sm:mt-0 sm:gap-y-0 md:space-x-6 lg:flex lg:space-x-10">
              <div className="flex flex-col">
                <span
                  className={`ml-4 flex text-base sm:ml-24 sm:text-lg md:ml-5 lg:ml-32 ${headerColor} font-bold`}
                >
                  Company
                </span>
                <div className="mt-3 sm:mt-5">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="https://redact.dev/blog"
                    className={`ml-4 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Blog
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="/contact"
                    className={`mt-2 ml-4 sm:mt-3 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Contact Us
                  </Link>
                  {/* <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="/ai-optout"
                    className={`mt-2 ml-4 sm:mt-3 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    AI Opt Out
                  </Link> */}
                </div>
              </div>

              <div className="flex flex-col">
                <span
                  className={`ml-4 flex text-base sm:ml-24 sm:text-lg md:ml-5 lg:ml-32 ${headerColor} font-bold`}
                >
                  Media
                </span>
                <div className="mt-3 sm:mt-5">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="/affiliates"
                    className={`ml-4 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Affiliates
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="/press-kit"
                    className={`mt-2 ml-4 sm:mt-3 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Press Kit
                  </Link>
                </div>
              </div>

              <div className="flex flex-col">
                <span
                  className={`ml-4 flex text-base sm:ml-24 sm:text-lg md:ml-5 lg:ml-32 ${headerColor} font-bold`}
                >
                  Legal
                </span>
                <div className="mt-3 sm:mt-5">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="/privacy"
                    className={`ml-4 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="/terms"
                    className={`mt-2 ml-4 sm:mt-3 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>

              <div className="flex flex-col">
                <span
                  className={`ml-4 flex text-base sm:ml-24 sm:text-lg md:ml-5 lg:ml-32 ${headerColor} font-bold`}
                >
                  Socials
                </span>
                <div className="mt-3 sm:mt-5">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="https://x.com/redactdev"
                    target="_blank"
                    className={`ml-4 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Twitter
                    <span className="icon-[fluent--open-12-regular] mt-1 ml-1"></span>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="https://www.reddit.com/r/redact/"
                    target="_blank"
                    className={`mt-2 ml-4 sm:mt-3 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Reddit
                    <span className="icon-[fluent--open-12-regular] mt-1 ml-1"></span>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: `${theme === "light" ? "#4D4D4D" : "#DEDEDE"}`,
                    }}
                    href="https://discord.com/invite/PbyTFBEhtV"
                    target="_blank"
                    className={`mt-2 ml-4 sm:mt-3 sm:ml-24 md:ml-5 lg:ml-32 ${linkClass}`}
                  >
                    Discord
                    <span className="icon-[fluent--open-12-regular] mt-1 ml-1"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8 flex sm:mt-0">
            <p className={`ml-4 text-sm sm:ml-5 sm:text-base ${textColor} opacity-65`}>
              {copyrightString}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
